import { Box, Button, Text } from 'condolivre-ds'
import Image from 'next/image'
import style from './modal.module.scss'
import PropTypes from 'prop-types'
import { ErrorTypes } from '../../invoices/applications/constants'

export const SuccessModal = () => {
    return (
        <Box className={style.container}>
            <div className={style.emoji}>
                <Image src="/images/emoji_md_sucesso.png" width="60" height="60" />
            </div>
            <Text size={'v_22'} color={'#474E5E'} weight={'bold'}>
                Nota aprovada com sucesso!
            </Text>
            <div className={style.information}>
                <Text size={'v_14'} color={'#474E5E'} weight={'v_400'}>
                    A nota fiscal do seu condominio foi aprovada com sucesso! Agora iremos realizar
                    a programação do pagamento!
                </Text>
            </div>
        </Box>
    )
}

export const UnregisteredPhoneModal = () => {
    return (
        <Box className={style.container}>
            <>
                <div className={style.emoji}>
                    <Image src="/images/emoji_sm_erro.png" width="60" height="60" />
                </div>
                <Text size={'v_22'} color={'#474E5E'} weight={'bold'}>
                    Telefone não cadastrado!
                </Text>
                <div className={style.information}>
                    <Text size={'v_14'} color={'#474E5E'} weight={'v_400'}>
                        O número de telefone validado não consta em nossa base, tente novamente com
                        o telefone correto.
                    </Text>
                </div>
            </>
        </Box>
    )
}

export const InvoiceApplicationErrorModal = ({ error, show }) => {
    return (
        <Box className={style.container}>
            <>
                <div className={style.emoji}>
                    <Image
                        src={
                            error.type === ErrorTypes.DANGER
                                ? '/images/emoji_sm_erro.png'
                                : '/images/emoji_md_attention.png'
                        }
                        width="60"
                        height="60"
                    />
                </div>
                <Text size={'v_22'} color={'#474E5E'} weight={'bold'}>
                    {error.title}
                </Text>
                <div className={style.information}>
                    <Text size={'v_14'} color={'#474E5E'} weight={'v_400'}>
                        {error.description}
                    </Text>
                </div>
                {error.try_again && (
                    <Button size={'small'} onClick={() => show(false)}>
                        Tentar novamente
                    </Button>
                )}
            </>
        </Box>
    )
}

InvoiceApplicationErrorModal.propTypes = {
    error: PropTypes.object.isRequired,
    show: PropTypes.func.isRequired
}
