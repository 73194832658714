import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const AuthContext = React.createContext({
    isAuthenticated: false,
    setAuthenticated: () => {}
})

export const AuthProvider = ({ children, authenticated }) => {
    const [isAuthenticated, setAuthenticated] = React.useState(authenticated)
    const [userAuthenticated, setUserAuthenticated] = React.useState({})

    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(userAuthenticated))
    }, [userAuthenticated])

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                setAuthenticated,
                setUserAuthenticated
            }}>
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.node,
    authenticated: PropTypes.bool
}

export function useAuth() {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}

export function useIsAuthenticated() {
    const context = useAuth()
    return context.isAuthenticated
}
