import React, { useEffect } from 'react'
import styles from './index.module.scss'
import Image from 'next/image'
import { Box, InputText, Text, Button, InputCheckbox, Real } from 'condolivre-ds'
import { Col, Row, Container } from 'react-grid-system'
import { useState } from 'react'
import { useBreakpoint } from '@/helpers/withBreakpoint'
import { formatISODate } from '@/helpers/format'
import {
    getInvoiceApplicationDetails,
    approveInvoiceApplication,
    sendConfirmationToken
} from './network'
import { SuccessModal, InvoiceApplicationErrorModal } from './components/modals'
import {
    INVOICE_APPLICATION_NOT_FOUND_ERROR,
    InvoiceApplicationApproveErrors,
    InvoiceApplicationGetDetailsErrors
} from '../invoices/applications/constants'
import IframePdf from './components/IframePdf'

function Cipa() {
    const [checkbox, setCheckBox] = useState(false)
    const [invoiceApplicationError, setInvoiceApplicationError] = useState({})
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [invoiceApplication, setInvoiceApplication] = useState({})
    const [invoiceApplicationId, setInvoiceApplicationId] = useState('')
    const [resendTokenDisabled, setResendTokenDisabled] = useState(false)
    const [approveButton, setApproveButton] = useState(false)
    const [counter, setCounter] = useState(60)
    const [phone, setPhone] = useState('')
    const [token, setToken] = useState('')
    const [sendTokenError, setSendTokenError] = useState(false)
    const [invalidTokenError, setInvalidTokenError] = useState(false)
    const { isMobile } = useBreakpoint()

    const handleInvoiceApplicationErrors = (code, errorsList) => {
        const error = Object.values(errorsList).find((x) => x.code === (code || 'default'))
        setInvoiceApplicationError(error)
    }

    const handleInvoiceApplicationApproveErrors = (code) => {
        if (code === InvoiceApplicationApproveErrors.INVALID_TOKEN.code) {
            setInvalidTokenError(true)
            return
        }

        const error = Object.values(InvoiceApplicationApproveErrors).find(
            (x) => x.code === (code || 'default')
        )
        setInvoiceApplicationError(error)
    }

    useEffect(() => {
        const pathname = window.location.pathname
        const invoice_application_id = pathname.split('/')[3]
        const decodedId = atob(invoice_application_id)
        setInvoiceApplicationId(decodedId)

        if (decodedId) {
            getInvoiceApplicationDetails(decodedId).then((response) => {
                if (response.success) {
                    setInvoiceApplication(response.data)
                } else {
                    handleInvoiceApplicationErrors(
                        response.code,
                        InvoiceApplicationGetDetailsErrors
                    )
                }
            })
        } else {
            handleInvoiceApplicationErrors(
                INVOICE_APPLICATION_NOT_FOUND_ERROR.code,
                InvoiceApplicationGetDetailsErrors
            )
        }
    }, [])

    useEffect(() => {
        if (counter > 0 && resendTokenDisabled) {
            const timer = setTimeout(() => {
                setCounter((prevCounter) => prevCounter - 1)
            }, 1000)

            return () => clearTimeout(timer)
        } else {
            setResendTokenDisabled(false)
            setCounter(60)
        }
    }, [counter, resendTokenDisabled])

    useEffect(() => {
        checkbox === true && token.length === 6 ? setApproveButton(false) : setApproveButton(true)
    }, [checkbox, token])

    const handleSendCode = (id) => {
        sendConfirmationToken(id, phone).then((response) => {
            if (response.success) {
                setResendTokenDisabled(true)
            } else {
                setSendTokenError(true)
            }
        })
    }

    const handleCheckbox = () => {
        setCheckBox(!checkbox)
    }

    const handleApproveInvoiceApplication = () => {
        approveInvoiceApplication(invoiceApplicationId, phone, token).then((response) => {
            if (response.success) {
                setShowSuccessModal(true)
            } else {
                handleInvoiceApplicationApproveErrors(response.code)
            }
        })
    }

    const handleOpenInvoicePath = () => {
        window.open(invoiceApplication.nfe_path, '_blank')
    }

    return (
        <>
            <header className={styles.headerContainer}>
                <div className={styles.logo}>
                    <Image src="/images/cipa-icon.png" width="120" height="45" />
                </div>
            </header>
            <div className={styles.content}>
                {showSuccessModal ? (
                    <div className={styles.modalWrapper}>
                        <SuccessModal />
                    </div>
                ) : invoiceApplicationError.title ? (
                    <div className={styles.modalWrapper}>
                        <InvoiceApplicationErrorModal
                            error={invoiceApplicationError}
                            show={setInvoiceApplicationError}
                        />
                    </div>
                ) : (
                    <Box className={styles.boxWrapper}>
                        <div className={styles.boxTitle}>
                            <Text
                                size={isMobile ? 'v_16' : 'v_22'}
                                color={'#474E5E'}
                                weight={'bold'}>
                                Aprovação da NF para programação no contas a pagar
                            </Text>
                        </div>
                        <div className={styles.boxSubtitle}>
                            <Text
                                size={isMobile ? 'v_16' : 'v_18'}
                                color={'black'}
                                weight={isMobile ? 'none' : 'bold'}>
                                Informações da NF:
                            </Text>
                        </div>
                        <form>
                            <Container fluid className={styles.containerForm}>
                                <Row>
                                    <Col md={4}>
                                        <InputText
                                            label={'CNPJ condomínio'}
                                            value={invoiceApplication.company_cnpj}
                                            maskOptions={{ format: '##.###.###/####-##' }}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <InputText
                                            label={'Razão social'}
                                            value={invoiceApplication.company_name}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <InputText
                                            label={'Número da nota'}
                                            value={invoiceApplication.number}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <div className={styles.totalValueWrapper}>
                                            <Text size={'v_12'} weight={'v_400'}>
                                                Valor total
                                            </Text>
                                            <div className={styles.totalValue}>
                                                <Real>{invoiceApplication.value}</Real>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div>
                                            <InputText
                                                label={'Data de emissão'}
                                                value={formatISODate(
                                                    invoiceApplication.issue_date,
                                                    'dd/MM/yyyy'
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className={styles.fieldFullWidth}>
                                            <InputText
                                                label={'Data de vencimento'}
                                                value={formatISODate(
                                                    invoiceApplication.due_date,
                                                    'dd/MM/yyyy'
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <InputText
                                            label={'Parcelas'}
                                            value={invoiceApplication.installment_amount}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </form>
                        <IframePdf
                            isMobile={isMobile}
                            handleOpenInvoicePath={handleOpenInvoicePath}
                            invoiceApplication={invoiceApplication}
                        />
                        <div className={styles.containerPhone}>
                            <div className={styles.title}>
                                <Text
                                    size={isMobile ? 'v_18' : 'v_22'}
                                    color={'black'}
                                    weight={'v_600'}>
                                    Precisamos verificar sua identidade
                                </Text>
                            </div>
                            <div className={styles.phone}>
                                <Text
                                    size={isMobile ? 'v_14' : 'v_18'}
                                    color={'black'}
                                    weight={'v_400'}>
                                    Por qual telefone deseja receber o código de validação?
                                </Text>
                                <InputText
                                    id={'phone-input'}
                                    value={phone}
                                    label={'Telefone para validação'}
                                    placeholder={'Digite seu celular aqui'}
                                    onChange={(e) => setPhone(e.value)}
                                    maskOptions={{ format: '(##) #####-####' }}
                                />
                                <div className={styles.sendCodeButton}>
                                    <Button
                                        variant={'primary'}
                                        data-testid={'btn-token-submit'}
                                        disabled={resendTokenDisabled}
                                        onClick={() => handleSendCode(invoiceApplicationId)}>
                                        {resendTokenDisabled
                                            ? `Enviar novamente em ${counter}`
                                            : 'Enviar código'}
                                    </Button>
                                </div>
                                <div className={styles.tokenError}>
                                    {sendTokenError && (
                                        <Text
                                            size={isMobile ? 'v_12' : 'v_16'}
                                            color={'red'}
                                            weight="v_500">
                                            Erro ao enviar o código, tente novamente!
                                        </Text>
                                    )}
                                </div>
                                <div className={styles.codeWrapper}>
                                    <Text
                                        size={isMobile ? 'v_18' : 'v_20'}
                                        color={'black'}
                                        weight={'v_600'}>
                                        Inserir código
                                    </Text>
                                    <Text
                                        size={isMobile ? 'v_12' : 'v_20'}
                                        color={'black'}
                                        weight={'v_400'}>
                                        Digite o código que enviamos via SMS
                                    </Text>
                                    <div className={styles.codeInput}>
                                        <input
                                            data-testid={'token-input'}
                                            value={token}
                                            placeholder="Inserir código"
                                            onChange={(e) => setToken(e.target.value)}
                                        />
                                    </div>
                                    {invalidTokenError && (
                                        <Text
                                            size={isMobile ? 'v_16' : 'v_18'}
                                            color={'red'}
                                            weight={'v_400'}>
                                            Código incorreto
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.approvationWrapper}>
                            <div className={styles.checkboxWrapper}>
                                <div className={styles.checkbox}>
                                    <InputCheckbox
                                        data-testid={'approve-invoice-checkbox'}
                                        onClick={handleCheckbox}
                                    />
                                </div>
                                <Text
                                    size={isMobile ? 'v_14' : 'v_18'}
                                    color={'black'}
                                    weight={'v_400'}>
                                    Aprovo a NF para programação no contas a pagar
                                </Text>
                            </div>
                            <Button
                                variant={'primary'}
                                data-testid={'approve-invoice-btn-submit'}
                                disabled={approveButton}
                                onClick={handleApproveInvoiceApplication}>
                                Aprovar NF
                            </Button>
                        </div>
                    </Box>
                )}
            </div>
        </>
    )
}

export default Cipa
