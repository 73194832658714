export const SortingOrder = Object.freeze({
    DUE_DATE_ASCENDING: {
        name: 'DUE_DATE_ASCENDING',
        field: 'due_date',
        description: 'Vencimento mais recente',
        order: 'asc'
    },
    DUE_DATE_DESCENDING: {
        name: 'DUE_DATE_DESCENDING',
        field: 'due_date',
        description: 'Vencimento menos recente',
        order: 'desc'
    },
    VALUE_DESCENDING: {
        name: 'VALUE_DESCENDING',
        field: 'value',
        description: 'Valor maior',
        order: 'desc'
    },
    VALUE_ASCENDING: {
        name: 'VALUE_ASCENDING',
        field: 'value',
        description: 'Valor menor',
        order: 'asc'
    }
})

export const STEP = {
    FORM: 'form-data',
    ERROR: 'contact',
    PROCESSING: 'processing'
}

export const DEFAULT_FORM = {
    corporation_cnpj: '',
    corporation_name: '',
    company_cnpj: '',
    company_name: '',
    number: '',
    installment_amount: '',
    value: '',
    due_date: '',
    nfe_path: ''
}

export const ErrorTypes = {
    WARNING: 'WARNING',
    DANGER: 'DANGER',
    INFO: 'INFO'
}

export const INVOICE_APPLICATION_GENERIC_ERROR = {
    code: 'default',
    title: 'Ocorreu um erro',
    description: 'Infelizmente ocorreu um erro, por favor tente novamente',
    type: ErrorTypes.DANGER,
    try_again: true
}

export const INVOICE_APPLICATION_NOT_FOUND_ERROR = {
    code: 'invoice_application_not_found',
    title: 'Nota fiscal não encontrada',
    description: 'Verifique se o link acessado está correto',
    type: ErrorTypes.DANGER
}

export const InvoiceApplicationGetDetailsErrors = Object.freeze({
    INVOICE_APPLICATION_GENERIC_ERROR: INVOICE_APPLICATION_GENERIC_ERROR,
    INVOICE_APPLICATION_NOT_FOUND_ERROR: INVOICE_APPLICATION_NOT_FOUND_ERROR
})

export const InvoiceApplicationApproveErrors = Object.freeze({
    INVOICE_APPLICATION_GENERIC_ERROR: INVOICE_APPLICATION_GENERIC_ERROR,
    INVOICE_APPLICATION_NOT_FOUND_ERROR: INVOICE_APPLICATION_NOT_FOUND_ERROR,
    INVALID_TOKEN: {
        code: 'invoice_application_wrong_token'
    },
    ALREADY_APPROVED: {
        code: 'invoice_application_approved',
        title: 'Essa Nota Fiscal já foi aprovada!',
        description:
            'A Nota Fiscal já foi aprovada anteriormente e iniciamos a programação do pagamento!',
        type: ErrorTypes.WARNING
    },
    INVALID_CREDENTIAL: {
        code: 'invoice_application_cipa_endpoint',
        title: 'Erro de validação',
        description: 'Não foi possível validar suas credenciais de acesso!',
        type: ErrorTypes.WARNING
    },
    INVALID_MANAGER: {
        code: 'invoice_application_invalid_manager',
        title: 'Telefone não vinculado ao condomínio',
        description:
            'Este número de telefone não está vinculado ao condomínio cadastrado na Nota Fiscal! Verifique os dados cadastrais e tente novamente com o telefone correto',
        type: ErrorTypes.DANGER
    }
})
