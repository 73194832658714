import React from 'react'
import PropTypes from 'prop-types'

export const DeviceContext = React.createContext()

export default function DeviceProvider({ children, value }) {
    return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
}

DeviceProvider.propTypes = {
    children: PropTypes.any,
    value: PropTypes.string
}
