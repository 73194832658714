export const DEVICE_TYPE_MOBILE = 'MOBILE'
export const DEVICE_TYPE_DESKTOP = 'DESKTOP'

export const MOBILE_RESOLUTION = 375
export const TABLET_RESOLUTION = 768
export const DESKTOP_RESOLUTION = 1280
export const LARGE_RESOLUTION = 1920

export const WhatsAppPhone = Object.freeze({
    COMMERCIAL_OPERATOR: {
        name: 'COMMERCIAL_OPERATOR',
        phone: '5511942232039' // Isaque
    },
    LANDBOT_CHANNEL: {
        name: 'LANDBOT_CHANNEL',
        phone: '5511964179978'
    }
})

export const CONDO_NEW_MENU_PREFIX = '@condolivre-menu-'
export const CONDO_STORAGE_REFERRAL_CAMPAIGN = '@condolivre-banner-referral'
export const CONDO_STORAGE_DIGITAL_ACCOUNT_STATUS = '@condolivre-account-'
export const CONDO_STORAGE_PIX_KEY_CLAIM_ALERT_REMOVED =
    '@condolivre-account-pix-key-claim-alert-removed-'

export const ReferralsFAQ = [
    {
        title: '1. O que é o programa de indicação da Condolivre?',
        content:
            'Ofertamos uma taxa de antecipação competitiva para você que quer se tornar um parceiro da Condolivre, apoiando-nos com indicações a amigos e conhecidos. Isto é, você concorre a descontos nas taxas de antecipações a cada fornecedor indicado que realizar a primeira antecipação.'
    },
    {
        title: '2. Minha comissão tem prazo de validade?',
        content:
            'Não, uma vez indicado, não importa quanto tempo demore para que seu colega se habilite na plataforma e realize a primeira antecipação. Estaremos gerenciando esse processo para que assim que a ação se efetivar você possa se beneficiar com o programa.'
    },
    {
        title: '3. Vou ganhar dinheiro ao indicar um conhecido?',
        content:
            'Indiretamente sim! A remuneração do programa de indicação, atualmente baseia-se na redução das taxas operacionais, isto é, você não será contemplado com dinheiro depositado em conta, mas com o desconto adquirido nas próximas antecipação, a garantia de economia é certa, logo, de alguma forma você estará se beneficiando com o valor proporcional.'
    },
    {
        title: '4. Onde consigo meu código de indicação?',
        content:
            'Para ter um código de indicação é necessário se ativar no programa. Para isso, basta acessar nossa plataforma no menu Multi Cifra ou nas opções via chatbot. Se preferir, nosso consultor estará a disposição para mais informações nos telefones: (11) 94223-2039 e (11) 91665-3256.'
    },
    {
        title: '5. Como faço para participar do programa?',
        content:
            'Para participar do programa basta se ativar via plataforma ou chatbot, após o aceite do nosso regulamento, documento este que explica em detalhes todas as premissas do programa. Uma vez ativo, você irá receber um código pessoal para compartilhar com seus amigos e conhecidos e em caso de habilitação deles nas nossas plataformas, informando seu código, você será contemplado com o benefício de realizar antecipações de recebíveis com taxas mais baixas.'
    },
    {
        title: '6. Como resgato e consulto meus créditos?',
        content:
            'Garantimos uma estruturação interna para reconhecer o fornecedor que através de indicação se habilitou em nosso portal e realizou uma primeira antecipação. Realizada esta etapa, você deverá acompanhar em nosso submenu "Conquistas" o status das suas indicações particulares para usufruir das melhores taxas do mercado.'
    },
    {
        title: '7. Qual canal de suporte para esclarecimento das minhas dúvidas?',
        content:
            'As dúvidas relacionadas ao programa de indicação podem ser consultadas diretamente via cel com um de nossos consultores, nos seguintes números: (11) 94223-2039 e (11) 91665-3256.'
    },
    {
        title: '8. Preciso pagar para fazer parte do programa?',
        content: 'Não, nosso programa é gratuito.'
    },
    {
        title: '9. O que eu preciso fazer para indicar alguém?',
        content:
            'Basta informar sobre nosso programa e reforçar o pedido para que ao se habilitar em nosso portal do fornecedor, durante o cadastro seu amigo ou conhecido informe nos campos finais o seu código de indicação. Com isso ele estará vinculado a sua conta e após a primeira antecipação disponibilizaremos o benefício para você.'
    }
]

export const ENTER_KEY = 'Enter'

export const DECIMAL_PERCENTAGE_MASK = {
    suffix: '%',
    decimalScale: 2,
    decimalSeparator: ',',
    fixedDecimalScale: true,
    allowNegative: false
}
