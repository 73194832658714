import { useContext, useEffect, useState } from 'react'
import { useScreenClass } from 'react-grid-system'
import { DeviceContext } from '@/components/DeviceProvider/DeviceProvider'
import { DEVICE_TYPE_DESKTOP, DEVICE_TYPE_MOBILE } from '@/config/constants'

export const generateBreakpointProps = (screenClass) => {
    const breakpoint = {
        screenClass: screenClass,
        isMobile: ['xs', 'sm'].includes(screenClass),
        isDesktop: ['md', 'lg', 'xl', 'xxl'].includes(screenClass)
    }
    ;['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].forEach((media) => {
        breakpoint[media] = media === screenClass
    })

    return breakpoint
}

export const useBreakpoint = () => {
    const deviceType = useContext(DeviceContext)
    const [breakpoint, setBreakpoint] = useState({
        isDesktop: deviceType === DEVICE_TYPE_DESKTOP,
        isMobile: deviceType === DEVICE_TYPE_MOBILE
    })
    const screenClass = useScreenClass()
    useEffect(() => {
        setBreakpoint(generateBreakpointProps(screenClass))
    }, [screenClass])
    return breakpoint
}
