export const PartnerValidationErrors = Object.freeze({
    DUPLICATED_PARTNERS_CPF: {
        code: 'duplicated_partners_cpf',
        description: (
            <>
                <b>CPF duplicado</b>. Por favor, insira um CPF único.
            </>
        )
    }
})

export const truncateValue = (value) => {
    return Math.round(value * 100) / 100
}

export const FORMAT_TYPES = {
    CPF_CNPJ: 'CPF_CNPJ',
    PHONE_NUMBER: 'PHONE_NUMBER'
}
