import React from 'react'
import Script from 'next/script'
import PropTypes from 'prop-types'

const GoogleTagManager = ({ tagId }) => {
    return (
        <>
            <Script
                data-testid="google-tag-manager"
                dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${tagId}');`
                }}
            />

            <noscript>
                <iframe
                    title={'google-tagmanager'}
                    src={`https://www.googletagmanager.com/ns.html?id=${tagId}`}
                    height={'0'}
                    width={'0'}
                    style={{
                        display: 'none',
                        visibility: 'hidden'
                    }}></iframe>
            </noscript>
        </>
    )
}

GoogleTagManager.propTypes = {
    tagId: PropTypes.string.isRequired
}

export default GoogleTagManager
