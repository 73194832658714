export const mutationCreateInvoiceApplication = () => {
    return `
        mutation Mutation(
            $corporation_cnpj: String!
            $corporation_name: String!
            $company_cnpj: String!
            $company_name: String!
            $number: String!
            $installment_amount: Float!
            $value: Float!
            $due_date: String!
            $issue_date: String!
            $nfe_path: String!
            $external_id: String
        ) {
            createInvoiceApplication(
                corporation_cnpj: $corporation_cnpj,
                corporation_name: $corporation_name,
                company_cnpj: $company_cnpj,
                company_name: $company_name,
                number: $number,
                installment_amount: $installment_amount,
                value: $value,
                due_date: $due_date,
                issue_date: $issue_date,
                nfe_path: $nfe_path,
                company_external_id: $external_id
            ) {
                data {
                    approval_url
                }
                success
                error {
                    message
                }
            }
        }
    `
}

export const mutationApproveInvoiceApplication = () => {
    return `
        mutation ApproveInvoiceApplication(
            $_id: String!
            $phone: String!
            $token: String!
        ) {
            approveInvoiceApplication(
                _id: $_id,
                phone: $phone,
                token: $token
            ) {
                success
                error {
                    message,
                    code
                }
            }
        }
    `
}

export function mutationSendConfirmationToken() {
    return `
    mutation SendConfirmationToken($_id: String!, $phone: String!) {
      sendConfirmationToken(_id: $_id, phone: $phone){
        success
        error {
          message
        }
      }
    }
 `
}
