import React, { useState, useEffect } from 'react'
import { Button } from 'condolivre-ds'
import styles from '../index.module.scss'

const IframePdf = ({ isMobile, handleOpenInvoicePath, invoiceApplication }) => {
    const [filePath, setFilePath] = useState('')

    const getFileExtension = (url) => {
        const path = url.split('?')[0]
        const ext = path.split('.').pop()
        setFilePath(ext.toLowerCase())
    }

    useEffect(() => {
        if (invoiceApplication.nfe_path) {
            getFileExtension(invoiceApplication.nfe_path)
        }
    }, [invoiceApplication])

    if (isMobile) {
        return (
            <div className={styles.pdfBox}>
                <Button
                    variant={'primary'}
                    data-testid={'btn-download-nf'}
                    onClick={handleOpenInvoicePath}>
                    Download da NF
                </Button>
            </div>
        )
    } else {
        return (
            <div className={styles.pdfBox}>
                {filePath && filePath !== 'pdf' ? (
                    <div className={styles.imageContainer}>
                        <img
                            src={invoiceApplication.nfe_path}
                            alt="nfe-file"
                            className={styles.image}
                        />
                    </div>
                ) : (
                    <iframe
                        title="nfe-pdf-file"
                        src={invoiceApplication.nfe_path}
                        className={styles.iframe}
                        height="500"></iframe>
                )}
            </div>
        )
    }
}

export default IframePdf
