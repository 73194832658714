import { DateTime } from 'luxon'
import { FORMAT_TYPES } from './constants'
import { cpf as cpfValidator } from 'cpf-cnpj-validator'
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator'
import { isValidBrazilianPhoneNumber } from './validator'

export const onlyDigits = (value) => {
    if (!value) return value
    return value.replace(/\D/g, '')
}

export const formatISODate = (date, format) => {
    return DateTime.fromISO(date, { zone: 'utc' }).toFormat(format)
}

export const sizeOf = (bytes) => {
    if (bytes === 0) {
        return '0B'
    }
    const e = Math.floor(Math.log(bytes) / Math.log(1024))
    return (bytes / Math.pow(1024, e)).toFixed(0) + 'KMGTP'.charAt(e - 1) + 'B'
}

export const formatBankAccount = (account, digit) => {
    if (account && digit) {
        return `${account}-${digit}`
    } else if (account && !digit) {
        return `${account.slice(0, -1)}-${account.slice(-1)}`
    } else {
        return ''
    }
}

export const formatCurrencyBRL = (value) => {
    return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
}

export const formatCPFString = (cleanedValue) => {
    return cleanedValue?.replace(
        /(\*{3}|\d{3})(\*{3}|\d{3})(\*{3}|\d{3})(\*{2}|\d{2})/,
        '$1.$2.$3-$4'
    )
}

export const formatCNPJString = (cleanedValue) => {
    return cleanedValue?.replace(
        /(\*{2}|\d{2})(\*{3}|\d{3})(\*{3}|\d{3})(\*{4}|\d{4})(\*{2}|\d{2})/,
        '$1.$2.$3/$4-$5'
    )
}

export const formatCpfCnpjString = (value) => {
    const cleanedValue = onlyDigitsOrStar(value)
    if (cleanedValue?.length === 11) {
        return formatCPFString(cleanedValue)
    } else {
        return formatCNPJString(cleanedValue)
    }
}

export const formatPhoneNumberString = (value) => {
    const cleanedValue = onlyDigitsOrStar(value.replace('+55', ''))
    return cleanedValue.replace(/(\*{2}|\d{2})(\*{5}|\d{5})(\*{4}|\d{4})/, '($1) $2-$3')
}

export const formatPixKeyUnknownType = (value) => {
    if (cpfValidator.isValid(value)) {
        return formatCPFString(value)
    }
    if (isValidBrazilianPhoneNumber(value)) {
        return formatPhoneNumberString(value)
    }
    if (cnpjValidator.isValid(value)) {
        return formatCNPJString(value)
    }
    return value
}

export const formatPixKeyBasedOnType = (type, value) => {
    const formatters = {
        [FORMAT_TYPES.CPF_CNPJ]: formatCpfCnpjString,
        [FORMAT_TYPES.PHONE_NUMBER]: formatPhoneNumberString
    }

    return formatters[type] ? formatters[type](value) : value
}

export const onlyDigitsOrStar = (value) => {
    if (!value) return value
    return value.replace(/[^0-9*]/g, '')
}
