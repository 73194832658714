export function queryGetCompany() {
    return `
      query getCompany($cnpj: String!) {
        getCompany(cnpj: $cnpj){
          success
          data {
            corporate_name,
            corporation_cnpj,
            corporation_name,
            external_id
          }
          error {
            message
          }
        }
      }
   `
}

export function queryGetCorporationsAvailable() {
    return `
    query GetCorporations {
      getCorporations {
        data {
          _id,
          corporation_name
        }
        success
        error {
          message
        }
      }
    }
  `
}

export function queryGetSupplierCorporations() {
    return `
    query GetInvoiceApplicationSupplierCorporations {
        getInvoiceApplicationSupplierCorporations {
          data {
            _id,
            corporation_name
          }
          success
          error {
            message
          }
        }
      }
  `
}

export function queryGetSupplierCompanies() {
    return `
    query GetInvoiceApplicationSupplierCompanies {
      getInvoiceApplicationSupplierCompanies {
        data {
          _id,
          company_name
        }
        success
        error {
          message
        }
      }
    }
  `
}

export function queryGetInvoiceApplications() {
    return `
    query GetInvoiceApplications(
      $page: Float!,
      $page_size: Float!,
      $number: String!,
      $corporation_cnpj: String!,
      $start_date: String!,
      $end_date: String!
    ) {
        getInvoiceApplications(
          page: $page,
          page_size: $page_size,
          number: $number,
          corporation_cnpj: $corporation_cnpj,
          start_date: $start_date,
          end_date: $end_date
        ) {
            success
            data {
                _id,
                corporation_cnpj,
                corporation_name,
                company_cnpj,
                company_name,
                number,
                installment_amount,
                value,
                due_date,
                issue_date,
                nfe_path,
                approval_url
            }
            error {
              message
            }
        }
    }
  `
}

export function queryGetInvoiceApplicationDetails() {
    return `
    query GetDetails($_id: String!) {
      getInvoiceApplicationDetails(_id: $_id){
        success
        data {
          company_name,
          company_cnpj,
          number,
          value,
          installment_amount,
          issue_date,
          due_date,
          nfe_path
        }
        error {
          message,
          code
        }
      }
    }
 `
}
