export default {
    fixedmenu: {
        variant: {
            supply: {
                textColor: 'white',
                separatorLineColor: 'purple_600',
                backgroundColor: '#2D164A',
                backgroundActiveMenu: '#785AB8'
            }
        }
    }
}
