import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import App from 'next/app'
import cookie from 'cookie'
import { AuthProvider } from '@/providers/Auth'
import { DigitalAccountUserProvider } from '@/providers/DigitalAccountUser'
import { DateTime, Settings } from 'luxon'
import GoogleTagManager from '../components/GoogleTagManager/GoogleTagManager'
import { GlobalStyles } from 'condolivre-ds'
import { ScreenClassProvider } from 'react-grid-system'
import themeConfig from '@/config/theme'
import { getDeviceType, solveUserAgent } from '@/helpers'
import DeviceProvider from '@/components/DeviceProvider/DeviceProvider'
import { ServerStyleSheet } from 'styled-components'
import TagManager from 'react-gtm-module'
import { useRouter } from 'next/router'
import Cipa from './cipa'

import '@/styles/normalize.scss'
import '@/styles/globals.scss'
import '@/styles/alolan.scss'

Settings.defaultLocale = 'pt-BR'
DateTime.now().locale

MyApp.propTypes = {
    pageProps: PropTypes.object,
    authenticated: PropTypes.bool,
    digitalAccountAuthenticated: PropTypes.bool,
    googleTagManager: PropTypes.string,
    loading: PropTypes.bool,
    deviceType: PropTypes.string,
    Component: PropTypes.func
}
function MyApp({
    Component,
    pageProps,
    authenticated,
    digitalAccountAuthenticated,
    googleTagManager,
    deviceType
}) {
    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-WQMNNSB' })
    }, [])

    const router = useRouter()
    const [isCipa, setIsCipa] = useState(false)

    useEffect(() => {
        setIsCipa(window.location.toString().includes('cipa.'))
    }, [])

    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('931493264703026')
                ReactPixel.pageView()

                router.events.on('routeChangeComplete', () => {
                    ReactPixel.pageView()
                })
            })
    }, [router.events])

    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        setTimeout(function () {
            setRefresh(true)
        }, 100)
    }, [])
    return (
        <>
            <Head>
                <title> {isCipa ? 'CIPA' : 'Condo Livre'}</title>
            </Head>
            {googleTagManager != '' && <GoogleTagManager tagId={googleTagManager} />}
            {refresh && (
                <AuthProvider authenticated={authenticated}>
                    <DigitalAccountUserProvider authenticated={digitalAccountAuthenticated}>
                        <DeviceProvider value={deviceType}>
                            <GlobalStyles theme={themeConfig}>
                                <ScreenClassProvider>
                                    {isCipa ? <Cipa /> : <Component {...pageProps} />}
                                </ScreenClassProvider>
                            </GlobalStyles>
                        </DeviceProvider>
                    </DigitalAccountUserProvider>
                </AuthProvider>
            )}
        </>
    )
}

MyApp.getInitialProps = async (appContext) => {
    const sheet = new ServerStyleSheet()
    const originalRenderPage = appContext.renderPage
    let authenticated = false
    let digitalAccountAuthenticated = false
    const request = appContext.ctx.req

    try {
        appContext.renderPage = () =>
            originalRenderPage({
                enhanceApp: (App) => (props) => sheet.collectStyles(<App {...props} />)
            })

        if (request) {
            request.cookies = cookie.parse(request.headers.cookie || '')
            authenticated = !!request.cookies.session
            digitalAccountAuthenticated = !!request.cookies['da-session']
        }
        const userAgent = solveUserAgent(request)
        const deviceType = getDeviceType(userAgent)
        const appProps = await App.getInitialProps(appContext)
        return {
            ...appProps,
            authenticated,
            digitalAccountAuthenticated,
            deviceType,
            googleTagManager: process.env.GOOGLE_TAGMANAGER,
            styles: (
                <>
                    {appProps}
                    {sheet.getStyleElement()}
                </>
            )
        }
    } finally {
        sheet.seal()
    }
}

export default MyApp
