import graphQLClient from '@/helpers/graphQLService'
import { handleResponseError } from '@/helpers'
import { queryGetInvoiceApplicationDetails } from '@/pages/invoices/applications/query'
import {
    mutationApproveInvoiceApplication,
    mutationSendConfirmationToken
} from '@/pages/invoices/applications/mutations'

export const getInvoiceApplicationDetails = async (_id) => {
    try {
        const { data: response } = await graphQLClient.post('', {
            query: queryGetInvoiceApplicationDetails(),
            variables: { _id }
        })

        if (!response.data.getInvoiceApplicationDetails.success) {
            return {
                success: false,
                msg: response.data.getInvoiceApplicationDetails.error.message,
                code: response.data.getInvoiceApplicationDetails.error.code
            }
        }

        return {
            success: true,
            data: response.data.getInvoiceApplicationDetails.data
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const sendConfirmationToken = async (_id, phone) => {
    try {
        const { data: response } = await graphQLClient.post('', {
            query: mutationSendConfirmationToken(),
            variables: { _id, phone }
        })
        if (!response.data.sendConfirmationToken.success) {
            return {
                success: false,
                msg: response.data.sendConfirmationToken.error.message
            }
        }

        return {
            success: true
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const approveInvoiceApplication = async (_id, phone, token) => {
    try {
        const { data: response } = await graphQLClient.post('', {
            query: mutationApproveInvoiceApplication(),
            variables: { _id, phone, token }
        })
        if (!response.data.approveInvoiceApplication.success) {
            return {
                success: false,
                msg: response.data.approveInvoiceApplication.error.message,
                code: response.data.approveInvoiceApplication.error.code
            }
        }

        return {
            success: true
        }
    } catch (err) {
        return handleResponseError(err)
    }
}
